/**
 * A minor clean-up of react-mapbox-gl/map.
 */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import MapEvents from './MapEvents'
import MapOptions from './MapOptions'
import MapPosition from './MapPosition'
import MapInteraction from './MapInteraction'
import Children from './Children'
import elementResizeEvent from 'element-resize-event';
import { throttle } from './util/throttle';
import { MapContext } from './util/MapContext';
class MapGL extends React.Component {

  constructor(props){
    super(props);
    if(props.reuseUniqKey){
      this.reuseUniqKey = props.reuseUniqKey;
      this.useCache = true;
    }else{
      this.reuseUniqKey = _.uniqueId("reuseUniqKeyVoidConflict");
      this.useCache = false;
    }
  }

  static propTypes = {
    containerStyle: PropTypes.object,
    containerClassName: PropTypes.object,
    /** style 被 mapbox的style属性占用了 */
    // style: PropTypes.object,
    className: PropTypes.string,
    renderUnsupported: PropTypes.func,
    onLoad: PropTypes.func,
    onStyleLoad: PropTypes.func,
    reuseUniqKey: PropTypes.string,
    // MapOptions
    // MapPosition
    // MapInteraction
    // MapEvents
  }

  static defaultProps = {
    containerStyle: {
      width: '100%',
      height: '100%'
    }
  }

  static contextTypes = {
    mapboxgl: PropTypes.object,
    cache: PropTypes.object,
    addMapToCache: PropTypes.func,
    removeMapFromCache: PropTypes.func,
    cacheNamespace: PropTypes.string,
  }

  static childContextTypes = {
    map: PropTypes.object
  }

  state = {
    unsupported: false,
    map: null
  }

  getChildContext = () => ({
    map: this.state.map
  })
/*
  remove to fix [bug](http://git.azure.gagogroup.cn/gago-react-gl/core/issues/1)
  shouldComponentUpdate (nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) ||
      !_.isEqual(this.state, nextState)
    )
  }
*/

  componentWillMount(){
    this.cacheNamespace = this.context.cacheNamespace;
  }

  componentDidMount () {
    let {mapboxgl} = this.context
    
    if (mapboxgl.supported()) {
      this.createMap();
    } else {
      this.setState({unsupported: true})
    }
  }

  componentWillUnmount () {
    const {map} = this.state
    this.unmounted = true;
    
    this.unBindContainerResize();
    
    if (map) {
      // 移除所有事件
      map.off()
    }

    if(this.useCache){
      document.getElementById(this.mountPointKey()).removeChild(this.container);
      return;
    }

    if (map) {
      // NOTE: We need to defer removing the map to after all
      // children have unmounted
      setImmediate(() => {
        map.remove()
      })
    }
    

  }

  componentWillReceiveProps (nextProps) {
    let {map} = this.state
    if (map 
      && (
        !_.isEqual(this.props.containerStyle, nextProps.containerStyle) 
        || !_.isEqual(this.props.className, nextProps.className)
        || !_.isEqual(this.props.containerClassName, nextProps.containerClassName)
      )
    ) {
      this.updateStyle();
      map.resize()
    }
  }

  updateStyle(){
    this.container.className = `react-mapbox--container ${this.props.containerClassName || ''}`;
    if(this.props.containerStyle){
      for(const key in this.props.containerStyle){
        this.container.style.setProperty(key,this.props.containerStyle[key]);
      }
    }
  }

  mapInstanceKey(){
    return `react-mapbox--container-${this.cacheNamespace}-${this.reuseUniqKey}`;
  }

  mountPointKey(){
    return `react-mapbox--mount-point-${this.cacheNamespace}-${this.reuseUniqKey}`;
  }

  createMap () {
    let {mapboxgl,cache, addMapToCache} = this.context;
    if(document.getElementById(this.mapInstanceKey())){
      throw new Error(`reuseUniqKey 的值重复，重复值为： "${this.reuseUniqKey}"`);
    }
    if(this.useCache){
      if(cache[this.reuseUniqKey] != null){
        this.container = cache[this.reuseUniqKey].dom;
        this.container.id = this.mapInstanceKey();
        this.updateStyle();
        document.getElementById(this.mountPointKey()).appendChild(this.container);
        this.setState({
          map: cache[this.reuseUniqKey].map,
        });
        cache[this.reuseUniqKey].map.resize();
        // 模拟load事件
        if(this.props.onLoad){
          this.props.onLoad({
            target: cache[this.reuseUniqKey].map,
            type: "load"
          });
        }
        return cache[this.reuseUniqKey].map;
      }
    }
    
    this.container = window.document.createElement("div");
    this.container.id = this.mapInstanceKey();
    this.updateStyle();
    document.getElementById(this.mountPointKey()).appendChild(this.container);

    // Build options from sub-components.
    const options = _.extend(
      {container: this.container},
      MapOptions.getOptions(this.props),
      MapPosition.getOptions(this.props),
      MapInteraction.getOptions(this.props)
    )

    // Create map.
    const map = new mapboxgl.Map(options)

    // On map load, trigger events and set state.
    map.on('load', (...args) => {
      if (!this.unmounted) {
        this.bindContainerResize(map);
        if (this.props.onLoad) {
          this.props.onLoad(...args)
        }
        this.setState({map})
      }
    })

    // Optionally handle style.load.
    if (this.props.onStyleLoad) {
      map.on('style.load', this.props.onStyleLoad)
    }

    if(this.useCache){
      addMapToCache(this.reuseUniqKey,{
        dom: this.container,
        map:map
      });
    }
    return map
  }

  renderUnsupported () {
    return this.props.renderUnsupported ? (
      this.props.renderUnsupported()
    ) : (
      <div className='unsupported'>
        Your browser does not support WebGL-based maps.
      </div>
    )
  }

  bindContainerResize(map) {
    const element = this.container;
    elementResizeEvent(element, function(){
      throttle(map.resize.bind(map));
    });
  }

  unBindContainerResize() {
    const element = this.container;
    elementResizeEvent.unbind(element);
  }

  render () {
    const {containerStyle, containerClassName, style, className } = this.props
    const {unsupported, map} = this.state
    return (
      <>
        <div 
          id={this.mountPointKey()} 
          key={this.mountPointKey()}
          style={{width: "100%",height: "100%"}}
          className={`react-mapbox--mount-point ${className}`}
        />
        <>
          {unsupported ? (
            this.renderUnsupported()
          ) : map ? (
            <MapContext.Provider value={{map:this.state.map}} >
              <Children>
                <MapOptions {..._.pick(this.props, _.keys(MapOptions.propTypes))} />
                <MapPosition {..._.pick(this.props, _.keys(MapPosition.propTypes))} />
                <MapInteraction {..._.pick(this.props, _.keys(MapInteraction.propTypes))} />
                <MapEvents {..._.pick(this.props, _.keys(MapEvents.propTypes))} />
                {this.props.children}
              </Children>
            </MapContext.Provider>
          ) : null}
        </>
      </>
    )
  }
}

export default MapGL
