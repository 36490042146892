import { LercTileLayer } from "@gago-react-gl/core";
import Axios from "axios";
import { LercTilePaint } from "gago-mapbox-gl";
import { uniqueId } from "lodash/fp";
import uniq from "lodash/fp/uniq";
import * as React from "react";
import tinycolor from "tinycolor2";
import {
  BeforeAble,
  ClassificationLegendProps,
  Legendble,
  LercMaskable,
  Maskable,
  OnlinePaint,
  Opacityable,
  Showable
} from "../interfaces";
import { SmartLercLayer } from "../smart-layer";
import {
  SmartLercSource,
  SmartLercSourceProps
} from "../smart-lerc-source/smart-lerc-source";

export type Range = [number, number];
export type Sorts<T> = Record<keyof T, Range>;
export type SortsColor<T> = Record<keyof T, string>;

interface O {
  [index: string]: any;
  [index: number]: any;
}

export interface SortsOption<T> {
  /**
   * 确定分类的数据
   * @example {corn:[0,1],other:[1,2]}
   */
  insideSorts: Sorts<T>;
  /**
   * @description "all" 全部显示 或者 ["corn","other"]根据具体名称控制
   */
  insideActiveSorts: Array<keyof T> | "all";
  /**
   * 每个分类的颜色
   * @example {corn:'red',other:'blue'}
   */
  insideColors: SortsColor<T>;
}

export interface SortsOptionWithOnlineColour<T> {
  /**
   * 确定分类的数据
   * @example {corn:[0,1],other:[1,2]}
   */
  insideSorts: Sorts<T>;
  /**
   * @description "all" 全部显示 或者 ["corn","other"]根据具体名称控制
   */
  insideActiveSorts: Array<keyof T> | "all";
  /**
   * 在线配色
   */
  paintUrl: string;
}

export interface SourceProps {
  /**
   * 唯一标示
   */
  id: string;
  /**
   * 数据源
   */
  url: string;
  /**
   * 经纬度范围
   * !!只有 image 数据源才需要配置
   */
  coordinates?: number[][];
}

export type ClassificationLayerProps<T> = (
  | (SortsOption<T> & SmartLercSourceProps)
  | (SortsOptionWithOnlineColour<T> & SourceProps)) &
  Maskable &
  LercMaskable &
  Opacityable &
  Showable &
  BeforeAble &
  Legendble<ClassificationLegendProps<keyof T>>;
export interface ClassificationLayerState<T extends O> {
  insideActiveSorts?: Array<keyof T> | "all";
  paint?: OnlinePaint;
}

/**
 * 分类图层
 *
 * @export
 * @class ClassificationLayer
 * @extends {React.Component<ClassificationLayerProps<T>, ClassificationLayerState>}
 * @template T
 */
export class ClassificationLayer<T extends O> extends React.PureComponent<
  ClassificationLayerProps<T>,
  ClassificationLayerState<T>
> {
  static defaultProps = {
    show: true
  };
  state: ClassificationLayerState<T> = {
    insideActiveSorts: undefined,
    paint: undefined
  };

  constructor(props: ClassificationLayerProps<T> & { a: string }) {
    super(props);
  }

  componentDidMount() {
    if (this.hasOnlinePaint()) {
      Axios.get<{ data: { colour: OnlinePaint } }>(
        (this.props as { paintUrl: string }).paintUrl
      ).then(res => {
        this.setState({ paint: res.data.data.colour });
      });
    }
  }

  render() {
    if (this.hasOnlinePaint()) {
      if (this.onlinePaintLoaded()) {
        const { insideSorts } = this.props;
        const colorscale = this.getColorscale_();
        const insideSortsArray = Object.keys(insideSorts)
          .map(key => ({ key, range: insideSorts[key] as [number, number] }))
          .sort((a, b) => a.range[0] - b.range[0]);
        const classificationPaint = getClassificationColorsByColors(
          colorscale.stops
        );
        let legend: React.ReactNode;
        const classificationLegendProps: ClassificationLegendProps<keyof T> = {
          colors: insideSortsArray.map((item, index) => ({
            key: item.key,
            color: classificationPaint[index][2],
            show: this.show(item.key)
          })),
          toggle: this.toggle
        };
        if (this.props.children) {
          legend = this.props.children(classificationLegendProps);
        }

        const classificationSource = this.generateSource_();
        const classificationLayer = this.generateLayer_();
        return (
          <>
            {classificationSource}
            {classificationLayer}
            {legend}
          </>
        );
      } else {
        return null;
      }
    } else {
      try {
        const colorscale = this.getColorscale_();
        let a: React.ReactNode;
        const classificationLegendProps: ClassificationLegendProps<keyof T> = {
          colors: uniq(colorscale.stops.map(item => item[1])).map(
            (item, index) => ({
              key: colorscale.keys[index],
              color: item,
              show: this.show(colorscale.keys[index])
            })
          ),
          toggle: this.toggle
        };
        if (this.props.children) {
          a = this.props.children(classificationLegendProps);
        }

        const classificationSource = this.generateSource_();
        const classificationLayer = this.generateLayer_();
        return (
          <>
            {classificationSource}
            {classificationLayer}
            {a}
          </>
        );
      } catch (e) {
        throw new Error(`layer ${this.props.id} 缺少渐变色配置`);
      }
    }
  }

  private show = (key: keyof T) => {
    const insideActiveSorts = this.state.insideActiveSorts
      ? this.state.insideActiveSorts
      : this.props.insideActiveSorts;
    if (typeof insideActiveSorts === "string") {
      return true;
    } else {
      return insideActiveSorts.includes(key);
    }
  };

  private toggle = (key: keyof T) => {
    const keys = Object.keys(this.props.insideSorts) as Array<keyof T>;
    const insideActiveSorts = this.state.insideActiveSorts
      ? this.state.insideActiveSorts
      : this.props.insideActiveSorts;
    if (typeof insideActiveSorts === "string") {
      this.setState({
        insideActiveSorts: keys.filter(item => item !== key)
      });
    } else {
      if (insideActiveSorts.includes(key)) {
        this.setState({
          insideActiveSorts: insideActiveSorts.filter(item => item !== key)
        });
      } else {
        this.setState({
          insideActiveSorts: [...insideActiveSorts, key]
        });
      }
    }
  };

  private generateSource_() {
    const { id, url, coordinates } = this.props;

    if (this.hasOnlinePaint()) {
      if (this.onlinePaintLoaded()) {
        return (
          <SmartLercSource
            key="source"
            id={id}
            url={url}
            sourceMaxzoom={this.getOnlinePaint().maxzoom}
            sourceMinzoom={this.getOnlinePaint().minzoom}
            coordinates={coordinates}
          />
        );
      } else {
        throw new Error(`${this.props.id}缺少在线配色`);
      }
    } else {
      const { sourceMinzoom, sourceMaxzoom } = this.getPropsPaint();
      return (
        <SmartLercSource
          key="source"
          id={id}
          url={url}
          sourceMaxzoom={sourceMaxzoom}
          sourceMinzoom={sourceMinzoom}
          coordinates={coordinates}
        />
      );
    }
  }
  private generateLayer_() {
    const {
      id,
      show,
      opacity,
      value,
      before,
      maskSourceId,
      lercMaskSourceId,
      lercMaskInclude
    } = this.props;
    const include = this.getInclude_();
    const colorscale = this.getColorscale_();
    delete colorscale.keys;
    const paint: LercTilePaint = {
      "lerc-include": include,
      "raster-domain-max": colorscale.max,
      "raster-domain-min": colorscale.min,
      "raster-colorscale": colorscale
    };
    return (
      <SmartLercLayer<LercTileLayer>
        key="layer"
        show={show}
        value={value}
        type="lercv2"
        source={id}
        id={id}
        paint={paint}
        opacity={opacity}
        before={before}
        maskSourceId={maskSourceId}
        lercMaskSourceId={lercMaskSourceId}
        lercMaskInclude={lercMaskInclude}
      />
    );
  }
  private getInclude_(): Array<[number, number]> {
    const { insideSorts } = this.props;
    let { insideActiveSorts } = this.props;
    const insideActiveSortsInState = this.state.insideActiveSorts;
    if (insideActiveSortsInState !== undefined) {
      insideActiveSorts = insideActiveSortsInState;
    }
    const result: Array<[number, number]> = [];
    if (insideActiveSorts === "all") {
      return Object.values(insideSorts);
    }
    insideActiveSorts.map(key => {
      result.push(insideSorts[key]);
    });
    if (result.length === 0) {
      return [[1, -1]];
    }
    return result;
  }
  private getColorscale_() {
    if (this.hasOnlinePaint()) {
      if (this.onlinePaintLoaded()) {
        try {
          const { insideSorts } = this.getPropsPaint();
          const colours = this.getOnlinePaint().paint["raster-colorscale"]
            .stops;
          const insideSortsArray = Object.keys(insideSorts)
            .map(key => ({ key, range: insideSorts[key] as [number, number] }))
            .sort((a, b) => a.range[0] - b.range[0]);
          const keys: Array<keyof T> = [];

          let valueMax: number = -Infinity;
          let valueMin: number = Infinity;
          insideSortsArray.forEach(item => {
            const [min, max] = item.range;
            valueMax = valueMax > max ? valueMax : max;
            valueMin = valueMin < min ? valueMin : min;
            keys.push(item.key as keyof T);
          });
          return {
            keys,
            stops: colours,
            type: this.getOnlinePaint().paint["raster-colorscale"].type,
            min: this.getOnlinePaint().paint["raster-domain-min"],
            max: this.getOnlinePaint().paint["raster-domain-max"]
          };
        } catch (e) {
          throw new Error(`layer ${this.props.id} 缺少渐变色配置`);
        }
      } else {
        throw new Error(`${this.props.id} 配色未加载完成`);
      }
    } else {
      try {
        const { insideSorts, insideColors } = this.getPropsPaint();
        const keys: Array<keyof T> = [];
        const stops: Array<[number, string]> = [];
        let valueMax: number = -Infinity;
        let valueMin: number = Infinity;
        Object.keys(insideSorts).forEach(key => {
          const [min, max] = insideSorts[key];
          const color = insideColors[key];
          stops.push([min, color]);
          stops.push([max, color]);
          valueMax = valueMax > max ? valueMax : max;
          valueMin = valueMin < min ? valueMin : min;
          keys.push(key as keyof T);
        });
        stops.sort(([min1], [min2]) => min1 - min2);
        return {
          keys,
          stops,
          type: "interval" as "interval",
          min: valueMin,
          max: valueMax
        };
      } catch (e) {
        throw new Error(`layer ${this.props.id} 缺少渐变色配置`);
      }
    }
  }

  /**
   * 从props中获取paint
   *
   * @author 张卓诚
   * @date 2019-02-18
   * @private
   * @returns
   * @memberof GradientLayer
   */
  private getPropsPaint() {
    return this.props as SortsOption<T> & SmartLercSourceProps;
  }

  /**
   * 获取线上配色
   *
   * @author 张卓诚
   * @date 2019-02-18
   * @private
   * @memberof GradientLayer
   */
  private getOnlinePaint() {
    return this.state.paint as OnlinePaint;
  }

  /**
   * 在线配色加载完成
   *
   * @author 张卓诚
   * @date 2019-02-18
   * @private
   * @memberof GradientLayer
   */
  private onlinePaintLoaded() {
    return this.state.paint !== undefined;
  }

  /**
   * 有线上配色
   *
   * @author 张卓诚
   * @date 2019-02-18
   * @private
   * @memberof GradientLayer
   */
  private hasOnlinePaint() {
    const paintUrl = (this.props as SortsOptionWithOnlineColour<T>).paintUrl;
    return paintUrl !== undefined;
  }
}

export interface ClassificationEditorProps {
  colors: Array<[number, string]>;
  onChange?(e: ClassificationEditorEvent): void;
}

// interface ClassificationEditorState{
//   positoin: number;
//   rawColors: Array<[number, number, string, string]>;
// }

/**
 * 调色器事件
 *
 * @author 张卓诚
 * @date 2019-02-02
 * @export
 * @interface ClassificationEditorEvent
 */
export interface ClassificationEditorEvent {
  colors: ClassificationEditorProps["colors"];
  classification: {
    colors: string[];
    ranges: Array<[number, number]>;
  };
}

/**
 * 以rgba(0,0,0,0和不同色作为分隔点作为分割点
 * 飞行员风格，不要重构
 *
 * @author 张卓诚
 * @date 2019-02-02
 * @param {[number, string][]} colors
 * @returns {ClassificationEditorState["rawColors"]}
 */
function getClassificationColorsByColors(
  colors: Array<[number, string]>
): Array<[number, number, string, string]> {
  const cunkedColors = [];
  let chunk: Array<[number, string]> = [];
  for (let i = 0; i < colors.length; i++) {
    const color = colors[i];
    const isFirst = i === 0;
    const isLast = i === colors.length - 1;
    const chunkIsEmpty = chunk.length === 0;
    const colorIsOpatic = tinycolor(color[1]).toHex8String() === "#00000000";
    const colorIsChange = isFirst
      ? true
      : tinycolor(color[1]).toHex8String() !==
        tinycolor(colors[i - 1][1]).toHex8String();

    if (isFirst && isLast) {
      chunk.push(color);
      chunk.push([Infinity, color[1]]);
      cunkedColors.push(chunk);
      chunk = [];
    }
    if (isFirst && !isLast) {
      // tslint:disable-next-line:no-empty
      if (colorIsOpatic) {
      } else {
        chunk.push(color);
      }
    } else if (!isFirst && isLast) {
      if (colorIsOpatic) {
        chunk.push(color);
        cunkedColors.push(chunk);
        chunk = [];
      } else {
        if (chunkIsEmpty) {
          chunk.push(color);
          chunk.push([Infinity, color[1]]);
          cunkedColors.push(chunk);
          chunk = [];
        } else {
          if (colorIsChange) {
            chunk.push(color);
            cunkedColors.push(chunk);
            chunk = [];
            chunk.push(color);
            chunk.push([Infinity, color[1]]);
            cunkedColors.push(chunk);
            chunk = [];
          } else if (!colorIsChange) {
            chunk.push(color);
            chunk.push([Infinity, color[1]]);
            cunkedColors.push(chunk);
            chunk = [];
          }
        }
      }
    } else if (!isFirst && !isLast) {
      if (colorIsOpatic) {
        // tslint:disable-next-line:no-empty
        if (chunkIsEmpty) {
        } else if (!chunkIsEmpty) {
          chunk.push(color);
          cunkedColors.push(chunk);
          chunk = [];
        }
      } else if (!colorIsOpatic) {
        if (chunkIsEmpty) {
          chunk.push(color);
        } else if (!chunkIsEmpty) {
          if (colorIsChange) {
            chunk.push(color);
            cunkedColors.push(chunk);
            chunk = [];
            chunk.push(color);
          } else if (!colorIsChange) {
            chunk.push(color);
          }
        }
      }
    }
  }
  return cunkedColors.map<[number, number, string, string]>(item => [
    item[0][0],
    item[item.length - 1][0],
    item[0][1],
    uniqueId("classification-editor")
  ]);
}

/**
 * getClassificationColorsByColors 的反函数
 *
 * @author 张卓诚
 * @date 2019-02-26
 * @param {ClassificationEditorState["rawColors"]} rawColors
 * @returns {Array<[number, string]>}
 */
export function getColorsByClassificationColors(
  rawColors: Array<[number, number, string, string]>
): Array<[number, string]> {
  const stops: Array<[number, string]> = [];
  let valueMax: number = -Infinity;
  let valueMin: number = Infinity;
  rawColors.forEach((item, index, rawColorsArray) => {
    const [min, max] = item;
    const color = item[2];
    stops.push([min, color]);
    // 如果上界限与下一个色彩的下界限相接，那么不需要插入透明色
    if (index < rawColorsArray.length - 1) {
      // tslint:disable-next-line:no-empty
      if (item[1] === rawColorsArray[index + 1][0]) {
      } else {
        stops.push([max, "rgba(0,0,0,0)"]);
      }
    } else {
      // 最后插入一个终止色，用于转换到渐变阶梯渲染时保证配色正确
      if (Number.isFinite(max)) {
        stops.push([max, color]);
        stops.push([max, "rgba(0,0,0,0)"]);
        // tslint:disable-next-line:no-empty
      } else {
      }
    }

    valueMax = valueMax > max ? valueMax : max;
    valueMin = valueMin < min ? valueMin : min;
  });
  stops.sort(([min1], [min2]) => min1 - min2);
  return stops;
}
