import {
  LercTileLayer,
  RadioLayer,
  RadioLayerProps
} from "@gago-react-gl/core";
import {
  LercTerrainLayer,
  LercTerrainPaint,
  LercTilePaint
} from "gago-mapbox-gl";
import * as React from "react";
import { BeforeAble, LercMaskable, Maskable, Showable } from "../interfaces";
// import "./smart-lerc-layer.css";

export type SmartLayerProps<T> = RadioLayerProps<T> &
  Maskable &
  LercMaskable & {
    paint?: LercTerrainPaint | LercTilePaint;
    opacity?: number;
  } & Showable &
  BeforeAble;
export interface SmartLayerState {
  someKey?: any;
}

export class SmartLercLayer<
  T = LercTerrainLayer | LercTileLayer
> extends React.Component<SmartLayerProps<T>, SmartLayerState> {
  static defaultProps = {
    show: true,
    opacity: 1
  };
  render() {
    const {
      paint = {},
      maskSourceId,
      opacity,
      lercMaskSourceId,
      lercMaskInclude
    } = this.props;
    if (maskSourceId) {
      paint["geojson-mask-source"] = maskSourceId;
    } else if (lercMaskSourceId) {
      paint["lerc-mask-source"] = lercMaskSourceId;
      if (lercMaskInclude) {
        paint["lerc-mask-include"] = lercMaskInclude;
      }
    }
    paint["raster-opacity"] = opacity;
    return <RadioLayer {...this.props} paint={paint} />;
  }
}
