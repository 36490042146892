import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Layer } from '../core/src';

class MarkLayer extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };
  option = {
    type: 'background',
    layout: {
      visibility: 'none',
    },
  };
  render() {
    const { id, ...rest } = this.props;
    const option = this.option;
    return <Layer id={id} {...option} {...rest} />;
  }
}

export default MarkLayer;
