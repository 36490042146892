export default {
  style:{
    version: 8,
    name: 'GAGO',
    sprite: 'https://cdn.gagogroup.cn/sprite/', // 使用本地化sprite
    glyphs: 'https://cdn.gagogroup.cn/gagogl/glyphs/{fontstack}/{range}.pbf',
    sources: {},
    layers: [],
    dragRotate: true,
    center: [124.17142868, 49.7837025433],
    zoom: 2,
    maxZoom: 18,
  },
}
