import * as React from "react";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** 使用上下文
 * @author James Zhang
 * @param {React.Context<S>} 上下文
 * @returns 带有上下文的HOC
 */

export function withContext<S extends any>(Context: React.Context<S>) {
  return function <P extends S>(Component: React.ComponentType<P>) {
    return class extends React.PureComponent<Omit<P, keyof S>> {
      /** 因为本项目大量依赖propTypes的key来筛选props
       * 所以propTypes 是需要继承的
      */
      static propTypes = Component.propTypes;
      render(){
        return <Context.Consumer>{(data: S) => {return (<Component {...{ ...this.props as any, ...data as any }} />);}}</Context.Consumer>;
      }
      
    };
  };
}