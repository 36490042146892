import React, { Component } from 'react';
import shallowEqual from 'shallowequal';
import PropTypes from 'prop-types';
import { Layer } from '../../core/src';

class RadioLayer extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    show: PropTypes.bool,
    paint: PropTypes.object,
    layout: PropTypes.object,
    removeWhenHide: PropTypes.bool,
    loadingWhenHide: PropTypes.bool,

    type: PropTypes.oneOf([
      'fill',
      'line',
      'raster',
      'symbol',
      'circle',
      'lercv2',
      'lerc-terrain',
      'lerc-overlay',
      'lerc-3d',
      'heatmap',
      'lerc-compare',
      'hillshade',
    ]).isRequired,
  };
  static defaultProps = {
    value: undefined,
    show: undefined,
    loadingWhenHide: false,
    removeWhenHide: false,
    paint: {},
    layout: {},
  };
  static contextTypes = {
    selectedValue: PropTypes.any,
  };

  static LercType = [
    'lercv2',
    'lerc-terrain',
    'lerc-overlay',
    'lerc3d',
    'lerc-compare',
  ];

  static getOpacityProps(type, opacity) {
    if (type === 'symbol') {
      return {
        'text-opacity': opacity,
        'icon-opacity': opacity,
      };
    } else if (RadioLayer.LercType.indexOf(type) > -1) {
      return {
        'raster-opacity': opacity,
      };
    }
    return {
      [`${type}-opacity`]: opacity,
    };
  }

  state = {
    show: 'show' in this.props ? this.props.show : false,
  };

  componentWillReceiveProps(props) {
    if ('show' in props) {
      this.setState({ show: props.show });
    }
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !shallowEqual(this.props, nextProps) ||
      !shallowEqual(this.state, nextState) ||
      this.context.selectedValue !== nextContext.selectedValue
    );
  }
  render() {
    const {
      paint,
      type,
      value,
      removeWhenHide,
      loadingWhenHide,
      layout,
      ...rest
    } = this.props;
    let { show } = this.state;
    if (
      'selectedValue' in this.context &&
      typeof this.context.selectedValue !== 'undefined'
    ) {
      show = value === this.context.selectedValue;
    }
    if (removeWhenHide) {
      return show ? (
        <Layer {...rest} type={type} paint={paint} layout={layout} />
      ) : null;
    }
    if (loadingWhenHide) {
      let wrapPaint = { ...paint };
      if (!show) {
        wrapPaint = {
          ...paint,
          ...RadioLayer.getOpacityProps(type, 0),
        };
      }
      return <Layer {...rest} type={type} paint={wrapPaint} layout={layout} />;
    }
    let wrapLayout = { ...layout };
    if (!show) {
      wrapLayout = {
        ...layout,
        visibility: 'none',
      };
    }
    return <Layer {...rest} type={type} paint={paint} layout={wrapLayout} />;
  }
}

export default RadioLayer;
