import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withMapContext } from "./util/MapContext";

const SOURCE_PROPS = [
  'type',
  'data',
  'url',
  'urls',
  'tiles',
  'tileSize',
  'minzoom',
  'maxzoom',
  'coordinates',
  'buffer',
  'tolerance',
  'cluster',
  'clusterRadius',
  'clusterMaxZoom',
  'canvas',
  'animate',
  'headers',
  'lercVersion',
  'roundZoom',
  'geojson-mask',
  'bounds',
  'params',
  'zoomOffset'
];
const SOURCE_UPDATE_PROPS = SOURCE_PROPS.filter(key=>['data', 'coordinates', 'params'].indexOf(key)<0);
class Source extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'canvas',
      'geojson',
      'image',
      'raster',
      'vector',
      'video',
      'lerc_v2',
      'lercImageByTile',
      'lerc_tile',
      'lerc_mask',
      'lerc-image',
    ]).isRequired,
    data: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    dataRev: PropTypes.number,
    url: PropTypes.string,
    urls: PropTypes.array,
    tiles: PropTypes.array,
    bounds: PropTypes.array,
    tileSize: PropTypes.number,
    minzoom: PropTypes.number,
    maxzoom: PropTypes.number,
    coordinates: PropTypes.array,
    buffer: PropTypes.number,
    tolerance: PropTypes.number,
    cluster: PropTypes.bool,
    clusterRadius: PropTypes.number,
    clusterMaxZoom: PropTypes.number,
    canvas: PropTypes.string,
    animate: PropTypes.bool,
    logWhenChange: PropTypes.bool,
    zoomOffset: PropTypes.number,
  }
  static defaultProps = {
    logWhenChange: false,
  }
  static contextTypes = {
    map: PropTypes.object
  }

  /** 为了做引用计数的id，用来标记Layer的react实例 */
  uniqueId = _.uniqueId("source");

  shouldComponentUpdate (nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) ||
      !_.isEqual(this.state, nextState)
    )
  }

  componentDidMount () {
    const preRefCount = this.getSourceRefCount(this.props);
    this.addSourceRefCount(this.props);
    if(preRefCount === 0 && this.getSourceRefCount(this.props) > 0){
      this.addSource(this.props);
    }
  }

  componentWillUnmount () {
    const preRefCount = this.getSourceRefCount(this.props);
    this.reduceSourceRefCount(this.props);
    if(preRefCount > 0 && this.getSourceRefCount(this.props) === 0){
      this.removeSource(this.props);
    }
  }

  /**
   * 增加引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @memberof Source
   */
  addSourceRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.sourceRefCount){
      map.sourceRefCount = {};
    }
    if(!map.sourceRefCount[id]){
      map.sourceRefCount[id] = {};
    }
    map.sourceRefCount[id][this.uniqueId] = 1;
  }

  /**
   * 减少引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @memberof Source
   */
  reduceSourceRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.sourceRefCount){
      map.sourceRefCount = {};
    }
    if(!map.sourceRefCount[id]){
      map.sourceRefCount[id] = {};
    }
    delete map.sourceRefCount[id][this.uniqueId];
  }

  /**
   * 读取引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @returns
   * @memberof Source
   */
  getSourceRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.sourceRefCount){
      map.sourceRefCount = {};
    }
    if(!map.sourceRefCount[id]){
      map.sourceRefCount[id] = {};
    }
    let result = 0;
    for(let key in map.sourceRefCount[id]){
      result = result + map.sourceRefCount[id][key];
    }
    this.log("source " + id + " ref count ",result);
    return result;
  }

  /**
   * 重置layer的引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @memberof Source
   */
  resetLayerRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.layerRefCount){
      map.layerRefCount = {};
    }
    map.layerRefCount[id] = {};
  }

  componentWillReceiveProps (nextProps) {
    let {map} = this.context

    // If any of these props change, we have to just recreate the
    // source from scratch.
    const invalidate = SOURCE_UPDATE_PROPS;
    if (!_.isEqual(
      _.pick(this.props, invalidate),
      _.pick(nextProps, invalidate)
    )) {
      const preRefCount = this.getSourceRefCount(this.props);
      this.reduceSourceRefCount(this.props);
      if(preRefCount > 0 && this.getSourceRefCount(this.props) === 0){
        this.removeSource(this.props)
      }
      const preRefCount2 = this.getSourceRefCount(this.props);
      this.addSourceRefCount(nextProps);
      if(preRefCount2 === 0 && this.getSourceRefCount(nextProps) > 0){
        this.addSource(nextProps)
      }
      return
    }

    // The coordinates changed.
    if (!_.isEqual(this.props.coordinates, nextProps.coordinates)) {
      map.getSource(nextProps.id).setCoordinates(nextProps.coordinates)
    }

    // The data changed.
    if (!_.isEqual(this.props.data, nextProps.data)) {
      map.getSource(nextProps.id).setData(nextProps.data)
    }

    // The params changed.
    if (!_.isEqual(this.props.params, nextProps.params)) {
      map.getSource(nextProps.id).setParams(nextProps.params)
    }

    // The dataRev changed (perhaps a timestamp). Use this to 'refresh'
    // an external geojson source.
    if (this.props.dataRev !== nextProps.dataRev) {
      map.getSource(nextProps.id).setData(nextProps.data)
    }
  }

  addSource (props) {
    let {map} = this.context
    let options = {}

    // Grab basic options from props.
    _.extend(options, _.omitBy(_.pick(props, SOURCE_PROPS), _.isNil))

    // Add the source.
    this.log('add source',props.id)
    map.addSource(props.id, options)
    map.fire('_addSource', props.id)
  }

  removeSource (props) {
    let {map} = this.context
    const layers = this.getLayer(props);
    //先移除需要的已经占用的Layer 才能移除source
    layers.forEach(layer => {
      if(map.getLayer(layer.id)){
        // 将引用计数减到0
        this.resetLayerRefCount(layer);
        map.removeLayer(layer.id);
      }
    })
    this.log('remove source',props.id)
    map.removeSource(props.id)
    map.fire('_removeSource', props.id)
  }
  getLayer(props){
    let {map} = this.context
    const layers = map.getStyle().layers;
    return  layers.filter(layer => layer.source == props.id);
  }
  log(...mes){
    if(this.props.log){
      console.log(...mes);
    }
  }
  render () {
    const count = this.getSourceRefCount(this.props)
    if(count > 1){
      console.warn(`react中同时有${count}个${this.props.id}的source组件，可能会造成渲染冲突`);
    }
    return null
  }
}

export default withMapContext(Source)
