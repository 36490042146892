import * as React from "react";
import { withContext } from "./withContext";

const value = {
  map: undefined
};

export type MapState = typeof value;

// tslint:disable-next-line:variable-name
export const MapContext = React.createContext(value);

export function withMapContext(Component: React.ComponentClass<MapState>){
  return withContext(MapContext)(Component);
};