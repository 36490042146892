const keys = obj => Object.keys(obj);

const MAPTILE = {
  GAGOWMS: {
    Satellite: {
      Map: '//ditu.gagogroup.cn:7090/rest/wms?LAYERS=basic&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&FORMAT=image%2Fjpeg&SRS=WGS84&BBOX={bbox-epsg-4326}&WIDTH=256&HEIGHT=256',
    },
    Subdomains: [],
  },
  GAGO: {
    Satellite: {
      Map: '//cdn{s}.gagogroup.cn/satellite/{z}/{x}/{y}.jpg',
    },
    Subdomains: ['', '1', '2', '3'],
  },
  TianDiTu: {
    Normal: {
      Map: '//t{s}.gagogroup.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}',
      Annotion: '//t{s}.gagogroup.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}',
    },
    Satellite: {
      Map: '//t{s}.gagogroup.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}',
      Annotion: '//t{s}.gagogroup.cn/DataServer?T=cia_w&X={x}&Y={y}&L={z}',
    },
    Terrain: {
      Map: '//t{s}.gagogroup.cn/DataServer?T=ter_w&X={x}&Y={y}&L={z}',
      Annotion: '//t{s}.gagogroup.cn/DataServer?T=cta_w&X={x}&Y={y}&L={z}',
    },
    Subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
  },

  GaoDe: {
    Normal: {
      Map: '//webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}',
    },
    Satellite: {
      Map: '//webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
      Annotion: '//webst0{s}.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}',
    },
    Subdomains: ['1', '2', '3', '4'],
  },

  Google: {
    Normal: {
      Map: '//www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
    },
    Satellite: {
      Map: '//www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
    },
    Subdomains: [],
  },
  GoogleUnShift: {
    Satellite: {
      Map: '//khm0.google.com/kh/v=718&hl=en&x={x}&y={y}&z={z}&s=Gali',
    },
    Subdomains: [],
  },
};
export const getTiles = (type) => {
  const [source, sort, feature] = type.split('_');
  const mapUrl = MAPTILE[source][sort][feature];
  let tiles = [];
  if (MAPTILE[source].Subdomains.length !== 0) {
    tiles = MAPTILE[source].Subdomains.map(item => mapUrl.replace('{s}', item, 'g'));
  } else {
    tiles = [mapUrl];
  }
  return tiles;
};
const MAPLISTS = [];
keys(MAPTILE).forEach((source) => {
  keys(MAPTILE[source]).forEach((sort) => {
    if (sort === 'Subdomains') {
      return;
    }
    keys(MAPTILE[source][sort]).forEach((feature) => {
      MAPLISTS.push([source, sort, feature].join('_'));
    });
  });
});

export { MAPLISTS };
export default MAPTILE;
