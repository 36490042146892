import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import diff from "./util/diff";
import Children from "./Children";
import Source from "./Source";
import LayerEvents from "./LayerEvents";
import { withMapContext } from "./util/MapContext";

class Layer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      "fill",
      "line",
      "symbol",
      "circle",
      "fill-extrusion",
      "raster",
      "background",
      "lercv2",
      "lerc-terrain",
      "lerc-overlay",
      "lerc-3d",
      "heatmap",
      "lerc-compare",
      "hillshade",
    ]).isRequired,
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    sourceLayer: PropTypes.string,
    metadata: PropTypes.object,
    copy: PropTypes.string,
    minzoom: PropTypes.number,
    maxzoom: PropTypes.number,
    filter: PropTypes.array,
    layout: PropTypes.object,
    paint: PropTypes.object,
    before: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    logWhenChange: PropTypes.bool,
    // LayerEvents
  };
  static defaultProps = {
    logWhenChange: false,
  };
  static contextTypes = {
    map: PropTypes.object,
  };
  getBefore(props) {
    const before = props.before;
    let { map } = this.context;
    let resultBefore = "";
    if (typeof before === "string" && map.getLayer(before)) {
      resultBefore = before;
    } else if (Array.isArray(before)) {
      before.some(id => {
        if (map.getLayer(id)) {
          resultBefore = id;
          return true;
        }
      });
    }
    return resultBefore;
  }
  state = {
    added: false,
  };

  /** 为了做引用计数的id，用来标记Layer的react实例 */
  uniqueId = _.uniqueId("layer");

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    );
  }

  componentDidMount() {
    const preRefCount = this.getLayerRefCount(this.props);
    this.addLayerRefCount(this.props);
    if(preRefCount === 0 && this.getLayerRefCount(this.props) > 0){
      this.addLayer(this.props);
    }
  }

  componentWillUnmount() {
    const preRefCount = this.getLayerRefCount(this.props);
    this.reduceLayerRefCount(this.props);
    if(preRefCount > 0 && this.getLayerRefCount(this.props) === 0){
      this.removeLayer(this.props);
    }
  }

  /**
   * 增加引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @returns
   * @memberof Layer
   */
  addLayerRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.layerRefCount){
      map.layerRefCount = {};
    }
    if(!map.layerRefCount[id]){
      map.layerRefCount[id] = {};
    }
    map.layerRefCount[id][this.uniqueId] = 1;
  }

  /**
   * 减少引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @memberof Layer
   */
  reduceLayerRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.layerRefCount){
      map.layerRefCount = {};
    }
    if(!map.layerRefCount[id]){
      map.layerRefCount[id] = {};
    }
    map.layerRefCount[id][this.uniqueId] = 0;
  }

  /**
   * 读取引用计数
   *
   * @author 张卓诚
   * @param {*} props
   * @returns
   * @memberof Layer
   */
  getLayerRefCount(props){
    const { map } = this.context;
    const { id } = props;
    if(!map.layerRefCount){
      map.layerRefCount = {};
    }
    if(!map.layerRefCount[id]){
      map.layerRefCount[id] = {};
    }
    let result = 0;
    for(let key in map.layerRefCount[id]){
      result = result + map.layerRefCount[id][key];
    }
    this.log("layer " + id + " ref count ",result);
    return result;
  }

  componentWillReceiveProps(nextProps) {
    let { map } = this.context;
    if (!map.getLayer(this.props.id)) {
      this.addLayer(nextProps);
      return;
    }
    // Have to recreate layer if these change.
    if (
      // !map.getLayer('this.props.id') ||
      !_.isEqual(this.props.id, nextProps.id) ||
      !_.isEqual(this.props.type, nextProps.type) ||
      !_.isEqual(this.props.source, nextProps.source) ||
      !_.isEqual(this.props.sourceLayer, nextProps.sourceLayer) ||
      !_.isEqual(this.props.metadata, nextProps.metadata) ||
      !_.isEqual(this.props.copy, nextProps.copy)
    ) {
      const preRefCount = this.getLayerRefCount(this.props);
      this.reduceLayerRefCount(this.props);
      if(preRefCount > 0 && this.getLayerRefCount(this.props) === 0){
        this.removeLayer(this.props);
      }
      const preRefCount2 = this.getLayerRefCount(this.props);
      this.addLayerRefCount(nextProps);
      if(preRefCount2 === 0 && this.getLayerRefCount(nextProps) > 0){
        this.addLayer(nextProps);
      }
      return;
    }

    if (!_.isEqual(this.props.filter, nextProps.filter)) {
      map.setFilter(this.props.id, nextProps.filter);
    }

    _.each(
      diff(this.props.layout || {}, nextProps.layout || {}),
      ({ type, key, value }) => {
        map.setLayoutProperty(
          this.props.id,
          key,
          type === "remove" ? null : value,
        );
      },
    );

    _.each(
      diff(this.props.paint || {}, nextProps.paint || {}),
      ({ type, key, value }) => {
        map.setPaintProperty(
          this.props.id,
          key,
          type === "remove" ? null : value,
        );
      },
    );
    const currentBefore = this.getBefore(this.props);
    const nextBefore = this.getBefore(nextProps);
    if (currentBefore !== nextBefore) {
      this.log(`moveLayer ${this.props.id}, before ${nextBefore}`);
      map.moveLayer(this.props.id, nextBefore);
    }

    if (
      this.props.minZoom !== nextProps.minZoom ||
      this.props.maxZoom !== nextProps.maxZoom
    ) {
      map.setLayerZoomRange(
        this.props.id,
        nextProps.minZoom,
        nextProps.maxZoom,
      );
    }
  }

  addLayer(props) {
    let { map } = props;
    let options = {};

    // Grab basic options from props.
    _.extend(
      options,
      _.omitBy(
        _.pick(props, [
          "id",
          "type",
          "metadata",
          "minzoom",
          "maxzoom",
          "filter",
          "layout",
          "paint",
        ]),
        _.isNil,
      ),
    );

    // Grab 'ref' from 'copy'.
    if (props.copy) {
      options.ref = props.copy;
    }

    // Check if we have a source id or object.
    if (_.isPlainObject(props.source)) {
      options.source = props.source.id || `${props.id}-source`;
    } else if (props.type !== "background") {
      options.source = props.source;
    }
    if (props.sourceLayer) {
      options["source-layer"] = props.sourceLayer;
    }
    if (props.type !== "background" && !map.getSource(props.source)) {
      map.fire("error", {
        error: new Error(`gago-react-gl: layer.${props.id}.source not found!`),
      });
      return;
    }
    if (map.getLayer(props.id)) {
      map.fire("error", {
        error: new Error(`gago-react-gl: layer.${props.id} already exist!`),
      });
      return;
    }
    const before = this.getBefore(props);
    if (before) {
      this.log(`add layer:${options.id}, before ${before}`);
      map.addLayer(options, before);
    } else {
      this.log(`add layer:${options.id}`);
      map.addLayer(options);
    }
    this.setState({ added: true });
    // Add the layer to the map.
  }
  log(...mes) {
    if (this.props.logWhenChange) {
      console.log(...mes);
    }
  }
  removeLayer(props) {
    let { map } = props;
    if (!map.getLayer(props.id)) return;
    this.log(`remove layer:${props.id}`);
    map.removeLayer(props.id);
    map.fire("_removeLayer", props.id);
    this.setState({ added: false });
  }

  render() {
    const count = this.getLayerRefCount(this.props)
    if(count > 1){
      console.warn(`react中同时有${count}个${this.props.id}的layer组件，可能会造成渲染冲突`);
    }
    return (
      <Children>
        {_.isPlainObject(this.props.source) ? (
          <Source id={`${this.props.id}-source`} {...this.props.source} />
        ) : null}
        {this.state.added ? (
          <LayerEvents
            layer={this.props.id}
            {...LayerEvents.pickEvents(this.props)}
          />
        ) : null}
      </Children>
    );
  }
}

export default withMapContext(Layer);
