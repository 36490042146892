import React from 'react';
import PropTypes from 'prop-types';
import { Children, Source, Layer } from '../../core/src';
import { MAPLISTS, getTiles } from './layerConfig';

class BaseLayer extends React.Component {
  static getMapLists() {
    return MAPLISTS;
  }
  static _getTiles(type, custom) {
    let tiles;
    if (custom) {
      tiles = custom;
    } else {
      tiles = getTiles(type);
    }
    return tiles;
  }
  render() {
    const { layerOption, sourceOption, type, id, custom } = this.props;
    const tiles = BaseLayer._getTiles(type, custom);
    return (
    <Children>
     <Source
       id={id}
       type="raster"
       tiles={tiles}
       tileSize={256}
       {...sourceOption}
     />
    <Layer
      id={id}
      source={id}
      type="raster"
      {...layerOption}
    />
    </Children>
    );
  }
}
BaseLayer.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  layerOption: PropTypes.object,
  sourceOption: PropTypes.object,
  custom: PropTypes.array,
};
BaseLayer.defaultProps = {
  custom: null,
  layerOption: {},
  sourceOption: {},
};
export default BaseLayer;
