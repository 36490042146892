import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withMapContext } from "./util/MapContext";

class Marker extends React.Component {
  static propTypes = {
    coordinates: PropTypes.array,
    offset: PropTypes.array,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
  }

  static defaultProps = {
    offset: [0, 0]
  }

  static contextTypes = {
    map: PropTypes.object,
    mapboxgl: PropTypes.object
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props, nextProps) ||
      !_.isEqual(this.state, nextState)
    )
  }

  componentWillMount() {
    // Setup marker div.
    this.el = document.createElement('div')
    // this.el.innerHTML = 'hello~';
    this.el.className = `react-mapbox--marker ${this.props.className}`
    _.extend(this.el.style, {
      position: 'relative',
      width: 0,
      height: 0,
      // 'background':'#ddd',
      overflow: 'visible'
    },
    this.props.style)

    // Add marker to map.
    this.addMarker(this.props)
  }

  componentWillUnmount() {
    this.marker.remove()
    this.el = null
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.coordinates, nextProps.coordinates)) {
      this.marker.setLngLat(nextProps.coordinates)
    }
    if (!_.isEqual(this.props.offset, nextProps.offset)) {
      this.removeMarker()
      this.addMarker(nextProps)
    }
    if (!_.isEqual(this.props.className, nextProps.className)) {
      this.updateClassName(nextProps);
    }
    if (!_.isEqual(this.props.style, nextProps.style)) {
      this.updateStyle(nextProps);
    }
  }

  shouldComponentUpdate(nextProps){
    return this.props.children !== nextProps.children;
  }

  addMarker(props) {
    let { map, mapboxgl } = this.context;
    this.el.className = `react-mapbox--marker ${this.props.className}`
    _.extend(this.el.style, {
      position: 'relative',
      width: 0,
      height: 0,
      // 'background':'#ddd',
      overflow: 'visible'
    },
    this.props.style);
    this.marker = new mapboxgl.Marker(this.el, { offset: props.offset });
    if (props.coordinates) {
      this.marker.setLngLat(props.coordinates);
    }
    this.marker.addTo(map);
  }

  removeMarker() {
    this.marker.remove()
    this.marker = null
  }
  
  updateClassName(nextProps){
    const originElClassName = this.el.className.split(" ");
    const originPropsClassName = this.props.className.split(" ");
    const newPropsClassName = nextProps.className.split(" ");

    const newElClassName = originElClassName.filter((className)=>{
      return className !== "" && originPropsClassName.indexOf(className) === -1
    })
    .concat(newPropsClassName)

    const newClassName = newElClassName.join(" ");
    this.el.className = newClassName;
  }

  updateStyle(nextProps){
    _.extend(this.el.style, {
      position: 'relative',
      width: 0,
      height: 0,
      // 'background':'#ddd',
      overflow: 'visible'
    },
    nextProps.style
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

export default withMapContext(Marker);


// import React, {cloneElement, PureComponent } from 'react';
// import mapboxgl from 'gago-mapbox-gl';
// import PropTypes from 'prop-types';

// const propTypes = {
//   coordinates: PropTypes.oneOfType(PropTypes.object,PropTypes.array),
//   offset: PropTypes.object,
// };

// const contextTypes = {
//   map: PropTypes.object,
//   mapboxgl: PropTypes.object,
// };

// export default class Marker extends PureComponent {
//   static propTypes = propTypes;
//   static contextTypes = contextTypes;

//   container;
//   marker;

//   get originRef() {
//     return this.marker;
//   }

//   __node = this.marker

//   // 获取marker引用
//   _getRef() {
//     this.props.getRef(this.marker);
//   }

//   _init() {
//     const { offset, coordinates } = this.props;
//     const { mapboxgl, map } = this.context;
//     if (!coordinates) return;
//     const marker = new mapboxgl.Marker(this.container, {
//       offset: offset || [0, 0],
//     }).setLngLat(coordinates).addTo(map);
//     this.marker = marker;
//   }

//   componentDidMount() {
//     const { map } = this.context;
//     if (map) {
//       this._init();
//     }
//   }

//   // shouldComponentUpdate(nextProps) {
//   // return !nextProps.lngLat.equals(this.props.lngLat);
//   // }

//   componentDidUpdate(preProps) {
//     const { coordinates } = this.props;
//     const { map } = this.context;
//     if (map && !this.marker) {
//       this._init();
//     }
//     // if (this.marker && !preProps.lngLat.equals(lngLat)) {
//     //   const lng = lngLat.get('lng');
//     //   const lat = lngLat.get('lat');
//     //   this.marker.setLngLat([lng, lat]);
//     // }
//   }

//   componentWillUnmount() {
//     // this.marker.remove();
//   }

//   render() {
//     return (
//       <div
//         ref={(el) => { this.container = el; }} className="gago-marker"
//       >
//         {this.props.children}
//       </div>
//     );
//   }
// }

