import React from 'react'
import PropTypes from 'prop-types'
import mapboxgl from 'gago-mapbox-gl'
// import loadCSS from './util/loadCSS'

/**
 * @description 提供gago-mapbox-gl库的组件，用来包裹MapGL
 * @description 也是 MapGL 的 reuseUniqKey 缓存特性的仓库
 */
export class MapboxProvider extends React.PureComponent {
  constructor(props){
    super(props);
    this.cacheNamespace = _.uniqueId("mapbox-provider-cache-namespace");
  }
  static propTypes = {
    // We need an access token.
    // Either pass in the mapbox-gl module ...
    // ... or we'll try to grab mapboxgl from the window ...
    // ... or we'll load the js/css from mapbox's CDN.
    css: PropTypes.bool,
    mapboxgl: PropTypes.object,
  }


  static childContextTypes = {
    mapboxgl: PropTypes.object,
    cache: PropTypes.object,
    addMapToCache: PropTypes.func,
    removeMapFromCache: PropTypes.func,
    cacheNamespace: PropTypes.string,
  }

  state = {
    mapboxgl: null,
    cache: {},
  }
  static defaultProps = {
    css: true,
    mapboxgl: mapboxgl,
  }

  getChildContext = () => ({
    mapboxgl: this.state.mapboxgl,
    addMapToCache: (name,map)=>{
      this.setState({
        cache: {
          ...this.state.cache,
          [name]: map
        }
      })
    },

    removeMapFromCache: (name)=>{
      this.setState({
        cache: {
          ...this.state.cache,
          [name]: undefined
        }
      })
    },
    cache: this.state.cache,
    cacheNamespace: this.cacheNamespace,
  })

  componentDidMount () {
    this.props.mapboxgl.accessToken = this.props.accessToken;
    this.setState({mapboxgl: this.props.mapboxgl})
  }

  render () {
    return this.state.mapboxgl ? this.props.children : null
  }
}
