import { LercImageSource, LercTileSource, Source } from "@gago-react-gl/core";
import * as React from "react";
import { RasterSourceProps } from "../interfaces";
// import "./smart-lerc-source.css";

export type SmartLercSourceProps = RasterSourceProps;
export interface SmartLercSourceState {
  someKey?: any;
}
export class SmartLercSource extends React.Component<
  RasterSourceProps,
  SmartLercSourceState
> {
  render() {
    const source = this.generateSource_();
    return source;
  }
  private generateSource_() {
    const { id, url, coordinates, sourceMaxzoom, sourceMinzoom } = this.props;
    if (coordinates) {
      return (
        <Source<LercImageSource>
          type="lerc-image"
          id={id}
          url={url}
          coordinates={coordinates}
        />
      );
    } else {
      return (
        <Source<LercTileSource>
          type="lerc_v2"
          id={id}
          tileSize={256}
          tiles={[url]}
          maxzoom={sourceMaxzoom}
          minzoom={sourceMinzoom}
        />
      );
    }
  }
}
